<template>
  <spinner v-if="loading" centered />
  <div v-else-if="resource" class="app-panel">
    <div id="task-detail-container" class="app-panel-body scrollable">
      <nav class="py-3 px-4 mb-2 d-flex align-items-center justify-content-between">
        <div>
          <a href="#" @click.prevent="close" class="fs-18 mr-3 ml-1" style="position: relative; top: 2px">
            <fa-icon name="times" />
          </a>
        </div>
        <div v-if="resource.canAccess">
          <dropdown>
            <template v-slot:title>
              <span>{{ $t("panes.taskDetail.followup") }}</span>
              <fa-icon name="angle-down" class="ml-1" />
            </template>
            <dropdown-section v-if="letterTemplates.length > 0">
              <dropdown-item
                v-for="item in letterTemplates"
                :key="item.id"
                :title="item.name"
                :link="`/services/letters/${item.id}?task_id=${taskId}`"
                new-tab
              />
            </dropdown-section>
            <dropdown-section v-if="resource.isEvent">
              <dropdown-item :title="$t('panes.taskDetail.eventConfirmation')" @click="quickviewMail()" />
            </dropdown-section>
            <dropdown-section>
              <dropdown-item :title="$t('panes.taskDetail.followupNote')" @click="followup('note')" />
              <dropdown-item :title="$t('panes.taskDetail.followupTask')" @click="followup('todo')" />
              <dropdown-item :title="$t('panes.taskDetail.followupEvent')" @click="followup('event')" />
              <dropdown-item :title="$t('panes.taskDetail.followupMail')" @click="followup('mail')" />
              <dropdown-item :title="$t('panes.taskDetail.followupLetter')" @click="followup('letter')" />
              <dropdown-item :title="$t('panes.taskDetail.cancellation')" @click="followup('reason')" />
            </dropdown-section>
          </dropdown>
          <ps-button
            v-if="!resource.locked && !resource.readOnly"
            @click="edit"
            icon="pencil"
            :title="$t('panes.taskDetail.edit')"
          />
          <dropdown>
            <dropdown-section>
              <dropdown-item new-tab :title="$t('panes.taskDetail.printView')" @click="printPageVisible = true" />
              <template v-if="resource.canDelete">
                <dropdown-item
                  @click="deleteTask"
                  :title="$t('panes.taskDetail.deleteTask')"
                  class="text-red-600"
                  v-if="!resource.recurring"
                />
                <dropdown-item
                  @click="deleteTask"
                  :title="$t('panes.taskDetail.deleteSeries')"
                  class="text-red-600"
                  v-if="resource.recurring"
                />
                <dropdown-item
                  @click="() => deleteTask(date)"
                  :title="$t('panes.taskDetail.deleteEvent')"
                  class="text-red-600"
                  v-if="resource.recurring && date"
                />
              </template>
            </dropdown-section>
            <dropdown-section>
              <p @click.stop class="text-gray-600 text-xs px-3 py-2">
                {{ $t("panes.taskDetail.id", { id: externalId }) }}
              </p>
            </dropdown-section>
          </dropdown>
        </div>
      </nav>
      <main class="flex">
        <todo-checkbox class="mr-auto my-1 ml-6" :resource="resource" :class="{ invisible: !resource.isReminder }" />
        <div class="py-0 pl-4 pr-6 w-11/12">
          <header class="form-group">
            <span
              v-if="resource.locked"
              class="bg-green-50 border-green-100 border rounded py-1 px-2 text-sm mb-2 inline-flex items-center"
            >
              <fa-icon name="shield-check" class="text-green-700 mr-1" />
              {{ $t("panes.taskDetail.locked") }}
            </span>
            <h3
              class="notes-title"
              :class="{ archived: resource.done, 'text-yellow-600 line-through': resource.reviewState == 'pending' }"
            >
              <nice-tooltip v-if="resource.confirmedByParticipant" :content="$t('panes.taskDetail.eventAcknowledged')">
                <fa-icon name="badge-check" class="text-success mr-1" />
              </nice-tooltip>
              {{ resource.title }}
            </h3>
          </header>

          <div class="mb-1" v-if="resource.category">
            <label class="mr-2">{{ $t("panes.taskDetail.type") }}</label>
            <strong>{{ resource.category.name }}</strong>
          </div>
          <div class="mb-1" v-if="isDevBackdoor && resource.syncErrorMessage">
            <label class="mr-2">
              <fa-icon name="exclamation-triangle" class="text-yellow-600" />
              {{ $t("panes.taskDetail.transferError") }}
            </label>
            <strong>{{ resource.syncErrorMessage }}</strong>
          </div>

          <div class="mb-1" v-if="broker">
            <label class="mr-2">{{ $t("panes.taskDetail.assignedTo") }}</label>
            <strong>{{ broker.name }}</strong>
          </div>
          <div class="mb-1" v-else-if="resource.department">
            <label class="mr-2">{{ $t("panes.taskDetail.assignedTo") }}</label>
            <strong>{{ resource.department.name }}</strong>
          </div>

          <div
            class="d-flex mb-2"
            v-if="!(resource.clients.length == 0 && resource.properties.length == 0 && resource.projects.length == 0)"
          >
            <span class="msg-participantLabel mr-2" style="line-height: 1.7">{{ $t("panes.taskDetail.linked") }}</span>
            <p>
              <message-participant
                v-for="r in resource.clients.map(n => ({ ...n, client: n }))"
                :key="r.id"
                :participant="r"
                :task-id="taskId"
              />
              <br v-if="resource.properties.length > 0 && resource.clients.length > 0" />
              <property-card v-for="r in resource.properties" :key="r.id" :property="r" :task-id="taskId" />
              <br v-if="resource.projects.length > 0 && resource.clients.length > 0" />
              <router-link
                v-for="r in resource.projects"
                :key="r.id"
                :to="r.path"
                style="padding: 6px; line-height: 1.7"
              >
                {{ r.name }}
              </router-link>
            </p>
          </div>

          <div class="mb-1" v-if="accessBrokers.length > 0 || accessDepartments.length > 0">
            <label class="mr-2">{{ $t("panes.taskDetail.rights") }}</label>
            <strong>
              {{
                accessBrokers
                  .concat(accessDepartments)
                  .map(b => b.name)
                  .join(", ")
              }}
            </strong>
          </div>
          <div class="mb-1" v-if="resource.clientSource">
            <label class="mr-2">{{ $t("panes.taskDetail.source") }}</label>
            <strong>{{ resource.clientSource.name }}</strong>
          </div>

          <div class="mb-1" v-if="taskStage">
            <label class="mr-2">{{ $t("panes.taskForm.taskStageId") }}</label>
            <dropdown width="280">
              <template v-slot:reference>
                <strong class="cursor-pointer" :style="{ color: taskStage.color }">{{ taskStage.name }}</strong>
              </template>
              <dropdown-section style="max-height: 250px">
                <dropdown-item
                  v-for="item in selectablePhases(resource)"
                  :key="item.id"
                  :title="item.name"
                  @click="changeTaskStage(resource, item.id)"
                  style="padding: 0 10px"
                  class="flex justify-between"
                >
                  <div class="flex items-center">
                    <div
                      class="rounded-full opacity-50"
                      :style="`background-color: ${item.color}; width: 8px; height: 8px; margin-right: 10px;`"
                    ></div>
                    {{ item.name }}
                  </div>
                  <!-- <span class="ml-1 text-gray-400 text-sm">{{ Math.round(item.chance * 100) }}%</span> -->
                </dropdown-item>
              </dropdown-section>
            </dropdown>
          </div>

          <div class="mb-1" v-if="resource.lastDealStageName">
            <label class="mr-2">{{ $t("panes.taskDetail.dealStage") }}</label>
            <strong>{{ resource.lastDealStageName }}</strong>
          </div>

          <div class="mb-1" v-if="resource.cancelationReason">
            <label class="mr-2">{{ $t("panes.taskDetail.cancellationReason") }}</label>
            <strong>{{ resource.cancelationReason.name }}</strong>
          </div>

          <div class="mb-1" v-if="reviewers.length">
            <label class="mr-2">{{ $t("panes.taskForm.reviewers") }}</label>
            <strong>{{ reviewers.map(o => o.name).join(", ") }}</strong>
          </div>
          <div class="mb-1" v-if="resource.brokers.length > 0">
            <label class="mr-2">{{ $t("panes.taskDetail.subscribedBy") }}</label>
            <strong>{{ resource.brokers.map(o => o.name).join(", ") }}</strong>
          </div>

          <div class="mb-1" v-if="resource.isNote && resource.startsAt">
            <label class="mr-2">{{ $t("panes.taskDetail.dateIs") }}</label>
            <span>{{ $customFilters.datetime(resource.startsAt) }}</span>
          </div>

          <template v-if="hasCustomFields">
            <div class="mb-1" v-for="(field, idx) in customFields" :key="idx">
              <label class="mr-2">{{ field.label }}</label>
              <strong>
                <custom-value
                  :cf="{ fieldType: field.formField.type, ...field.formField, ...field }"
                  :resource="resource"
                  :show-price-unit="true"
                />
              </strong>
            </div>
          </template>

          <div
            class="mb-1 grid grid-cols-2 auto-rows-min gap-x-2 w-100"
            v-if="resource.previousActivity || resource.nextActivities.length"
          >
            <a
              v-if="resource.previousActivity"
              @click.prevent="quickview(resource.previousActivity)"
              class="text-black rounded px-2 py-1 h-8 truncate activityBox"
            >
              <fa-icon name="long-arrow-left" />
              {{ resource.previousActivity.name }} ({{ resource.previousActivity.type == "message" ? "M" : "T"
              }}{{ resource.previousActivity.id }})
            </a>
            <a
              v-for="item in totalActivities"
              :key="item.id"
              @click.prevent="quickview(item)"
              class="text-black rounded px-2 py-1 h-8 truncate activityBox"
            >
              <fa-icon name="long-arrow-right" />
              {{ item.name }} ({{ item.type == "message" ? "M" : "T" }}{{ item.id }})
            </a>
            <div
              v-if="resource.nextActivities.length > (resource.previousActivity ? 5 : 6)"
              class="col-span-2 text-right mr-2"
            >
              <button @click="showAllActivities = !showAllActivities" class="text-lg">
                <fa-icon v-if="showAllActivities" name="chevron-up" />
                <fa-icon v-else name="chevron-down" />
              </button>
            </div>
          </div>

          <decision-details v-if="type === 'decision'" :resource="resource" class="mb-3">
            <div class="form-group mt-3">
              <div class="msg-body" v-html="resource.body"></div>
            </div>
          </decision-details>
          <event-details v-if="resource.isEvent" :resource="resource" :date="date" class="mb-3">
            <div class="form-group mt-3">
              <div class="msg-body" v-html="resource.body"></div>
            </div>
          </event-details>
          <todo-details v-if="resource.isReminder" :resource="resource" class="mb-3">
            <div class="form-group mt-3">
              <div class="msg-body" v-html="resource.body"></div>
            </div>
          </todo-details>
          <scout-inquiry-details v-if="resource.taskType == 'scout_inquiry'" :resource="resource" class="mb-3" />

          <div v-if="!resource.isEvent && !resource.isReminder && type !== 'decision'" class="form-group mt-3">
            <div class="msg-body" v-html="resource.body"></div>
          </div>

          <div class="mb-1" v-if="resource.isCampaign">
            <p class="text-primary">
              <strong>{{ $t("panes.taskDetail.contactCount", { count: resource.clientsCount }) }}</strong>
            </p>
          </div>

          <div class="mb-3" v-if="resource.groups.length > 0">
            <h4 class="text-base">{{ $t("panes.taskDetail.tags") }}</h4>
            <nice-bar v-for="item in resource.groups" :key="item.id" type="light" :label="item.name" class="mx-0" />
          </div>
          <div class="mb-3" v-if="resource.noteAttachments.length > 0">
            <h4 class="text-base">{{ $t("panes.taskDetail.data") }}</h4>
            <nice-bar v-for="item in resource.noteAttachments" :key="item.id" type="light" class="mx-0">
              <a :href="item.url" target="_blank">{{ item.name }}</a>
            </nice-bar>
          </div>

          <div class="mb-3" v-if="resource.docusignData">
            <h4 class="text-base">DocuSign</h4>
            <div class="mt-2" v-if="resource.docusignData.signer">
              <label class="mr-2">{{ $t("panes.taskDetail.signedBy") }}</label>
              <strong>{{ resource.docusignData.signer.name }} ({{ resource.docusignData.signer.email }})</strong>
            </div>
            <div v-if="resource.docusignData.signed_at">
              <label class="mr-2">{{ $t("panes.taskDetail.signedOn") }}</label>
              <strong>{{ $customFilters.datetime(resource.docusignData.signed_at) }}</strong>
            </div>
            <div class="mt-2" v-if="resource.docusignData.form_data">
              <h4 class="text-base">{{ $t("panes.taskDetail.formData") }}</h4>
              <div class="mt-2">
                <div v-for="item in Object.entries(resource.docusignData.form_data)" :key="item[0]">
                  <label class="mr-2">{{ item[0] || $t("panes.taskDetail.unnamed") }}</label>
                  <strong>
                    <formatted-value :value="item[1]" />
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div class="px-4 my-3" v-if="initialClientFormData">
        <client-form
          :initial-data="initialClientFormData"
          @close="initialClientFormData = null"
          @create="clientCreated"
          :task="resource"
        />
      </div>

      <comments-app :resource-id="taskId" v-model:watchers="resource.watcherBrokerIds" resource-type="Task">
        <div v-if="resource.synced" class="activityLog-item">
          <p>{{ $t("panes.taskDetail.eventSync") }}</p>
          <nice-tooltip
            class="activityLog-date"
            :content="$customFilters.datetime(resource.createdAt)"
            placement="left"
          >
            <span>
              {{ $customFilters.calendar(resource.createdAt) }}
            </span>
          </nice-tooltip>
        </div>
        <div v-else-if="taskCreator" class="activityLog-item">
          <p>
            <strong>{{ taskCreator.internalName }}</strong>
            {{ $t("panes.taskDetail.createdBy") }}
          </p>
          <nice-tooltip
            class="activityLog-date"
            :content="$customFilters.datetime(resource.createdAt)"
            placement="left"
          >
            <span>
              {{ $customFilters.calendar(resource.createdAt) }}
            </span>
          </nice-tooltip>
        </div>
      </comments-app>
    </div>
    <task-print v-model:open="printPageVisible" :task="resource" />
  </div>
</template>

<script>
import CommentsApp from "@/components/Comments"
import EventDetails from "@/components/task/EventDetails"
import DecisionDetails from "@/components/task/DecisionDetails"
import TodoDetails from "@/components/task/TodoDetails"
import ScoutInquiryDetails from "@/components/task/ScoutInquiryDetails"
import { getTaskType } from "@/utils/get-task-type"
import eventBus from "@/config/event-bus"
import CustomValue from "@/components/Table/CustomValue.vue"
import FormattedValue from "@/components/Table/FormattedValue.vue"
import TaskPrint from "@/views/print/Task.vue"
import detailViewForm from "@/config/detail-view-form"
import TodoCheckbox from "@/components/task/TodoCheckbox"
import MessageParticipant from "@/components/Inbox/MessageParticipant.vue"
import PropertyCard from "@/components/Inbox/PropertyCard.vue"
import ClientForm from "@/components/Inbox/ClientForm"
import useEnvironment from "@/plugins/use-environment"
import { useBroker } from "@/composables/use-broker"

const deserializeCustomFields = params => ({
  ..._.omit(params, ["customFields"]),
  ..._.mapKeys(params.customFields, (_, key) => `cf_${key}`),
})

export default {
  props: {
    id: { type: Number },
    type: { type: String },
    source: {
      type: Object,
    },
    date: { type: Date },
  },
  components: {
    FormattedValue,
    CustomValue,
    CommentsApp,
    EventDetails,
    TodoDetails,
    DecisionDetails,
    ScoutInquiryDetails,
    TodoCheckbox,
    TaskPrint,
    MessageParticipant,
    ClientForm,
    PropertyCard,
  },
  setup() {
    const { isDevBackdoor } = useEnvironment()
    const { broker: currentBroker, getBrokerById } = useBroker()
    return { isDevBackdoor, currentBroker, getBrokerById }
  },
  data() {
    return {
      taskId: this.id,
      loading: true,
      resource: null,
      printPageVisible: false,
      showAllActivities: false,
      initialClientFormData: null,
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const { task } = await this.$graphql(
          `query task {
        task(id: $id, date: $date) {
          id createdAt title body oldCrmExternalId taskType taskStageId brokerId
          isEvent isReminder isNote
          category { id name color taskPipelineId }
          clientSource { id name }
          cancelationReason { id name }
          department { name }
          brokers { id name }
          reviewerIds reviewState
          lastDealStageName isCampaign body
          creatorId synced syncErrorMessage
          canDelete

          done dueDate bufferBefore bufferAfter
          todos { id title brokerId dueDate doneAt departmentId }

          private location recurring roomResourceEmails readOnly
          startsAt endsAt allDay onlineMeetingUrl
          massViewingUrl possibleSpots maxParticipantsPerSlot
          viewings { id viewingDate phone email name clientId firstName lastName salutation attendance }
          clientsStatus state rateable
          suggestedEvent allSuggestedEvents { id startsAt }

          groups { id name }
          clients(limit: 100) { id name path emails phone email intlPhone } clientsCount
          properties { id name marketingType marketingTypeLabel marketingPrice categoryLabel rsTypeLabel zipCode city region country numberOfRooms livingSpace plotArea industrialArea constructionYear price valuationPrice valuationPriceFrom valuationPriceTo baseRent currency titleImageUrl path }
          projects { id name path }
          noteAttachments { id name url }
          canAccess confirmedByParticipant
          customFields
          accessBrokerIds accessDepartmentIds
          watcherBrokerIds

          recurringInstanceOriginalDate

          docusignData seekerPayload seekerAdditionalInfo
          previousActivity {
            id name type
          }
          nextActivities {
            id name type
          }
          locked
          decisionOptions { id name }
          decisionOptionId
          departmentId
        }
      }`,
          { id: this.taskId, date: this.date }
        )

        this.resource = deserializeCustomFields(task)
        if (this.resource.isCampaign) this.resource.clients = []
        this.loading = false
      } catch (e) {
        console.error(e)
        this.$emit("error")
      }
    },
    quickview({ type, id }) {
      eventBus.$emit("quick-view", {
        type: type === "reminder" ? "todo" : type,
        mode: "view",
        id,
      })
    },
    quickviewMail() {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            taskId: this.taskId,
          },
        },
      })
    },
    quickviewMailForClient(client) {
      eventBus.$emit("quick-view", {
        key: "client",
        type: "mail",
        mode: "edit",
        params: {
          source: {
            taskId: this.taskId,
            clientIds: [client.id],
          },
        },
      })
    },
    deleteTask(date) {
      this.$warn(
        {
          title: this.$t("panes.taskDetail.delete.title"),
          desc: this.$t("panes.taskDetail.delete.desc"),
          confirmButtonText: this.$t("panes.taskDetail.delete.submit"),
        },
        () => {
          this.$api.mutation("destroyTask", {
            id: this.taskId,
            date,
          })
          this.$emit("close")
          App.flashy(this.$t("panes.taskDetail.delete.success"))
        }
      )
    },
    close() {
      this.$emit("close")
    },
    edit() {
      this.$emit("params", {
        type: getTaskType(this.resource),
        mode: "edit",
        id: this.resource.id,
        // params: { date: this.date },
        // postfix: this.date,
      })
    },
    followup(type) {
      this.$emit("params", {
        type: type,
        mode: "edit",
        params: {
          source: {
            title: this.resource.title,
            clientIds: type == "letter" ? this.resource.clients.map(({ id }) => id) : undefined,
            projectIds: type == "letter" ? this.resource.projects.map(({ id }) => id) : undefined,
            propertyIds: type == "letter" ? this.resource.properties.map(({ id }) => id) : undefined,
            taskId: type == "mail" || type == "letter" ? this.taskId : undefined,
          },
          date: this.date,
          followupTaskId: this.taskId,
        },
      })
    },
    clientCreated() {
      this.initialClientFormData = null
      this.fetchData()
    },
    selectablePhases(resource) {
      if (!resource.category || !resource.category.taskPipelineId) return
      return this.taskStages.filter(p => p.dealPipelineId === resource.category.taskPipelineId)
    },
    currentPhase(resource) {
      if (!resource.taskStageId || !resource.category || !resource.category.taskPipelineId) return
      return this.taskStages.find(
        p => p.dealPipelineId === resource.category.taskPipelineId && p.id === resource.taskStageId
      )
    },
    changeTaskStage(task, taskStageId) {
      if (task.taskStageId === taskStageId) return
      this.$api
        .mutation("updateTask", { id: task.id, payload: { taskStageId: taskStageId } })
        .then(_ => {
          App.flashy(this.$t("todos.stageUpdateSuccess"))
          task.taskStageId = taskStageId
        })
        .catch(err => {
          this.$axios.handleError(err)
        })
    },
    handleAddClient(data) {
      this.initialClientFormData = {
        home_phone: data.phone,
        first_name: data.firstName,
        last_name: data.lastName,
        emails: [data.email],
        salutation: data.salutation,
      }
    },
  },
  computed: {
    taskStages() {
      return this.$db.shopData.taskStages
    },
    broker() {
      return this.getBrokerById(this.resource.brokerId)
    },
    reviewers() {
      if (!this.resource || !this.resource.reviewerIds) return []
      return this.resource.reviewerIds.map(this.getBrokerById).filter(o => o)
    },
    accessBrokers() {
      if (!this.resource || !this.resource.accessBrokerIds) return []
      return this.resource.accessBrokerIds.map(this.getBrokerById).filter(o => o)
    },
    accessDepartments() {
      if (!this.resource || !this.resource.accessDepartmentIds) return []
      return this.resource.accessDepartmentIds
        .map(id => this.$db.shopData.departments.find(b => b.id == id))
        .filter(o => o)
    },
    taskDetailViewGroups() {
      const type = this.type === "reminder" ? "todo" : this.type
      return (this.$db.shopData.taskDetailViewGroups || []).reduce((agg, cur) => {
        if (
          cur.taskTypes.includes(type) &&
          (!cur.taskCategoryIds ||
            !cur.taskCategoryIds.length ||
            cur.taskCategoryIds.includes(this.resource.category?.id))
        ) {
          agg.push({
            ...cur,
            detailViewFields: cur.detailViewFields.filter(
              e =>
                e.taskTypes.includes(type) &&
                (!e.taskCategoryIds ||
                  !e.taskCategoryIds.length ||
                  e.taskCategoryIds.includes(this.resource.category?.id))
            ),
          })
        }
        return agg
      }, [])
    },
    customFields() {
      return detailViewForm({}, this.taskDetailViewGroups, false, this.$db, this.$db.shopData.customFieldGroupsForTasks)
        .map(detail => detail.fields)
        .flat()
        .map(group => ({
          customOptions: group.formField.options,
          fieldType: group.formField.type,
          ...group.formField,
          ...group,
        }))
        .filter(cf => this.resource[cf.fieldName])
    },
    hasCustomFields() {
      return this.taskDetailViewGroups.some(t => t.detailViewFields.length)
    },
    letterTemplates() {
      return this.$db.shopData.letterTemplates.filter(o => o.category == "activity")
    },
    taskCreator() {
      return this.getBrokerById(this.resource.creatorId)
    },
    externalId() {
      return this.resource.oldCrmExternalId || `T${this.taskId}`
    },
    totalActivities() {
      let shownActivities = 6
      if (this.resource.previousActivity) shownActivities = shownActivities - 1
      return this.showAllActivities
        ? this.resource.nextActivities
        : this.resource.nextActivities.slice(0, shownActivities)
    },
    taskStage() {
      return this.$db.shopData.taskStages.find(o => o.id == this.resource.taskStageId)
    },
  },
  mounted() {
    if (this.$route.query) this.fetchData()
    eventBus.$on("add-client", this.handleAddClient)
  },
  beforeUnmount() {
    eventBus.$off("add-client", this.handleAddClient)
  },
}
</script>

<style scoped>
.taskDetails-wrap {
  padding: 20px 20px 0 71px;
}

.activityBox {
  background-color: rgb(230, 230, 230);
  width: 220px;
  margin-bottom: 12px;
}

.activityBox:hover {
  background-color: rgb(205, 205, 205);
}
</style>
